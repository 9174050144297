export const SKILLS = [
  {
    title: "Frontend",
    icon: "./assets/images/frontend-icon.png",
    skills: [
      { skill: "HTML5", percentage: "98%" },
      { skill: "CSS3 (Tailwind/Bootstrap)", percentage: "90%" },
      { skill: "JavaScript", percentage: "85%" },
      { skill: "React.js", percentage: "70%" },
      { skill: "TypeScript", percentage: "60%" },
      { skill: "Vue.js", percentage: "40%" },
    ],
  },
  {
    title: "Backend",
    icon: "./assets/images/backend-icon.png",
    skills: [
      { skill: "Node.js", percentage: "70%" },
      { skill: "Express.js", percentage: "50%" },
      { skill: "Python", percentage: "80%" },
      { skill: "PHP", percentage: "90%" },
      { skill: "PHP Laravel", percentage: "60%" },
      { skill: "C#", percentage: "70%" },
      { skill: "C++", percentage: "30%" },
    ],
  },
  {
    title: "Database",
    icon: "./assets/images/database-icon.png",
    skills: [
      { skill: "MySQL", percentage: "90%" },
      { skill: "PostgreSQL", percentage: "60%" },
      { skill: "MongoDB ", percentage: "50%" },
    ],
  },
  {
    title: "Tools",
    icon: "./assets/images/tools-icon.png",
    skills: [
      { skill: "Git & GitHub", percentage: "85%" },
      { skill: "Docker", percentage: "85%" },
      { skill: "Visual Studio Code", percentage: "95%" },
      { skill: "Postman", percentage: "70%" },
      { skill: "Android Studio", percentage: "30%" },
      { skill: "Arduino IDE", percentage: "50%" },
    ],
  },
];

export const WORK_EXPERIENCE = [
  {
    title: "CYBER SECURITY BULGARIA",
    date: "June 2023 - February 2024",
    responsibilities: [
      "CYBER SECURITY BULGARIA is a website created to provide information about different types of cyber attacks. The aim of the website is to provide fast, easy and detailed information about cyber attacks.",
      "The site is created in HTML, CSS, JavaScript.",
      "Creation time 3 weeks.",
    ],
  },
  {
    title: "CODEX ACADEMY",
    date: "June 2023 - February 2024",
    responsibilities: [
      "CODEX ACADEMY is an innovative online platform designed to provide easy and fun programming education for users of all ages and skill levels.",
      "The site is created in HTML, CSS, JavaScript, PHP, MariaDB.",
      "Creation time 20 weeks.",
    ],
  },
];
